import { useShow } from '@pankod/refine-core'
import { Show, Typography, Tag } from '@pankod/refine-antd'

import { IPost } from 'interfaces'

const { Title, Text } = Typography

export const PostShow = () => {
    const { queryResult } = useShow<IPost>()
    const { data, isLoading } = queryResult
    const record = data?.data

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>ID</Title>
            <Text>{record?.postID}</Text>

            <Title level={5}>게시글타입</Title>
            <Text>
                <Tag>{record?.postType}</Tag>
            </Text>

            <Title level={5}>글내용</Title>
            <Text>{record?.content}</Text>

            <Title level={5}>유저</Title>
            <Text>{record?.user}</Text>

            <Title level={5}>유저타입</Title>
            <Text>
                <Tag>{record?.userType}</Tag>
            </Text>

            <Title level={5}>게시글</Title>
            <Text>{record?.content}</Text>

            <Title level={5}>작성시각</Title>
            <Text>{record?.createdAt}</Text>

            <Title level={5}>수정시각</Title>
            <Text>{record?.updatedAt}</Text>
        </Show>
    )
}
