import {
    List,
    Table,
    useTable,
    getDefaultSortOrder,
    Card,
    Col,
    Row,
    Form,
    FormProps,
    Icons,
    Input,
    Button,
    DateField,
    TextField,
} from '@pankod/refine-antd'
import { useNavigation, CrudFilters, HttpError } from '@pankod/refine-core'

import { IPost, IPostFilterVariables } from '../../interfaces'

export const PostList: React.FC = () => {
    const { tableProps, sorter, searchFormProps } = useTable<IPost, HttpError, IPostFilterVariables>({
        initialSorter: [
            {
                field: 'postID',
                order: 'desc',
            },
        ],
        onSearch: (params) => {
            const filters: CrudFilters = []
            const { postType } = params

            filters.push({
                field: 'postType',
                operator: 'contains',
                value: postType,
            })

            return filters
        },
    })
    const { edit } = useNavigation()

    return (
        <Row gutter={[16, 16]}>
            <Col xl={6} lg={24} xs={24}>
                <Card title="필터">
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col xl={18} xs={24}>
                <List>
                    <Table
                        {...tableProps}
                        rowKey="id"
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    edit('posts', record.id)
                                },
                            }
                        }}
                    >
                        <Table.Column
                            dataIndex="postID"
                            title="ID"
                            sorter
                            defaultSortOrder={getDefaultSortOrder('postID', sorter)}
                        />
                        <Table.Column dataIndex="content" title="내용" />
                        <Table.Column dataIndex="user" title="작성자" />
                        <Table.Column
                            dataIndex="postType"
                            title="게시글타입"
                            render={(type: string) => <TextField value={type} />}
                        />
                        <Table.Column dataIndex="userType" title="사용자타입" />
                        <Table.Column dataIndex="likeCount" title="좋아요 갯수" />
                        <Table.Column
                            dataIndex="createdAt"
                            title="작성시각"
                            render={(value) => <DateField format="LLL" value={value} />}
                        />
                        <Table.Column
                            dataIndex="updatedAt"
                            title="수정시각"
                            render={(value) => <DateField format="LLL" value={value} />}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    )
}

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
    return (
        <Form layout="vertical" {...props.formProps}>
            <Row gutter={[10, 0]} align="bottom">
                <Col xs={24} xl={24} md={12}>
                    <Form.Item label="게시글타입" name="postType">
                        <Input placeholder="타입 검색" prefix={<Icons.SearchOutlined />} />
                    </Form.Item>
                </Col>
                <Col xs={24} xl={24} md={8}>
                    <Form.Item>
                        <Button style={{ width: '100%' }} htmlType="submit" type="primary">
                            필터
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}
