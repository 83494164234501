import { Refine } from '@pankod/refine-core'
import { notificationProvider, Layout, ReadyPage, ErrorComponent } from '@pankod/refine-antd'
import routerProvider from '@pankod/refine-react-router-v6'
import dataProvider from '@pankod/refine-airtable'

import '@pankod/refine-antd/dist/styles.min.css'

import { PostList, PostShow, PostEdit } from './pages/posts'

function App() {
    const API_TOKEN = process.env.REACT_APP_AIRTABLE_API_TOKEN || ''
    const BASE_ID = process.env.REACT_APP_AIRTABLE_BASE_ID || ''
    const TABLE_ID = process.env.REACT_APP_AIRTABLE_TABLE_ID || ''

    return (
        <Refine
            notificationProvider={notificationProvider}
            Layout={Layout}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            routerProvider={routerProvider}
            dataProvider={dataProvider(API_TOKEN, BASE_ID)}
            resources={[
                {
                    name: TABLE_ID,
                    list: PostList,
                    show: PostShow,
                    edit: PostEdit,
                    options: { label: '게시글', route: 'posts' },
                },
            ]}
        />
    )
}

export default App
