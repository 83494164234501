import { useForm, Form, Input, Select, Edit, Typography, Space } from '@pankod/refine-antd'

import { IPost } from 'interfaces'

const { Title, Text } = Typography

export const PostEdit = () => {
    const { formProps, saveButtonProps, queryResult } = useForm<IPost>()
    const { data } = queryResult as any
    const record = data?.data

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <div>
                        <Title level={5}>ID</Title>
                        <Text>{record?.postID}</Text>
                    </div>

                    <div>
                        <Form.Item
                            label="게시글타입"
                            name="postType"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        label: '답변완료',
                                        value: '답변완료',
                                    },
                                    {
                                        label: '미답변',
                                        value: '답변완료',
                                    },
                                    {
                                        label: '미공개',
                                        value: '답변완료',
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label="글내용"
                            name="content"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="유저"
                            name="user"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="유저타입"
                            name="userType"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        label: 'Admin',
                                        value: 'Admin',
                                    },
                                    {
                                        label: 'Student',
                                        value: 'Student',
                                    },
                                ]}
                            />
                        </Form.Item>

                        <Title level={5}>작성시각</Title>
                        <Text>{record?.createdAt}</Text>
                        <Title level={5}>수정시각</Title>
                        <Text>{record?.updatedAt}</Text>
                    </div>
                </Space>
            </Form>
        </Edit>
    )
}
